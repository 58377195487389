import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { viewScrollEvent } from "../helpers/viewScroll";
import i18n from "../locales";

import Spinner from "./components/spinner/spinner";
import NavBar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";

import WOW from "wow.js";

// eslint-disable-next-line no-unused-vars
const initI18n = i18n;

function App() {
  useEffect(() => {
    viewScrollEvent();
    new WOW({
      live: false,
    }).init();
  }, []);

  return (
    <div className="app">
      <Spinner />
      <NavBar />
      <div id="landing-page">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default App;
