const CarouselItem = (props) => {
  return (
    <div className={`carousel-item ${props.active ? "active" : ""}`}>
      <img className="w-100" src={props.image} alt="car-1" />
      <div className="carousel-caption">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 pt-5">
              <h1 className="home-title fw-700 text-white mb-3 animated slideInDown">
                {props.title}
              </h1>
              <p className="fs-5 text-white-50 mb-5 animated slideInDown">
                {props.description}
              </p>
              <div className="d-flex justify-content-center">
                {/*<a
                  className="btn btn-primary btn-30 py-2 px-3 animated slideInDown"
                  href={props.detailsLink}
                >
                  Learn More
                  <div className="d-flex justify-content-center align-items-center btn-sm-square bg-white text-primary rounded-circle ms-2">
                    <i className="fa fa-arrow-right"></i>
                  </div>
                </a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselItem;
