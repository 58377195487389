const TeamMember = (props) => {
  return (
    <div className="col-lg-3 col-md-6">
      <div className="team-item position-relative rounded overflow-hidden">
        <div className="overflow-hidden">
          <img className="img-fluid" src={props.photo} alt="" />
        </div>
        <div className="team-text bg-light text-center p-4">
          <h5 className="fw-bold">{props.fullName}</h5>
          <p className="text-primary">{props.designation}</p>
          <div className="team-social text-center">
            {props.facebook && (
              <a className="btn btn-square" href={props.facebook}>
                <i className="fab fa-facebook-f"></i>
              </a>
            )}
            {props.twitter && (
              <a className="btn btn-square" href={props.twitter}>
                <i className="fab fa-twitter"></i>
              </a>
            )}
            {props.linkedin && (
              <a className="btn btn-square" href={props.linkedin}>
                <i className="fab fa-linkedin"></i>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
