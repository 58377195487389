import { useEffect } from "react";
import { Carousel } from "bootstrap";
import nelson from "../../../img/nelson.jpg";
import carousel1 from "../../../img/carousel-1.jpg";
import carousel3 from "../../../img/carousel-3.jpg";
import CarouselItem from "./carouselItem";

const LandingCarousel = () => {
  useEffect(() => {
    const carousel = new Carousel("#header-carousel");
  }, []);

  return (
    <div className="container-fluid p-0 mb-5">
      <div
        id="header-carousel"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <CarouselItem
            image={nelson}
            title={
              "“L'éducation est l'arme la plus puissante qu'on puisse utiliser pour changer le monde”"
            }
            description={"Nelson Mandela"}
            detailsLink="#"
            active
          />
          <CarouselItem
            image={carousel1}
            title={"Ensemble changeons le monde par l'éducation"}
            description={""}
            detailsLink="#"
          />
          <CarouselItem
            image={carousel3}
            title={"Rejoignez-nous pour agir pour l'éducation en Afrique"}
            description={""}
            detailsLink="#"
          />
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default LandingCarousel;
