const Breadcrumb = (props) => {
  return (
    <nav aria-label="breadcrumb animated slideInDown">
      <ol className="breadcrumb justify-content-center mb-0">
        {props.links.map((link) =>
          link.active ? (
            <li
              key={link.key}
              className="breadcrumb-item text-primary active"
              aria-current="page"
            >
              {link.name}
            </li>
          ) : (
            <li key={link.key} className="breadcrumb-item">
              <a className="text-white" href={link.url}>
                {link.name}
              </a>
            </li>
          )
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
