import Objectives from "../components/objectives/objectives";
import Historique from "../components/historique/historique";
import PageHeader from "../components/pageHeader/pageheader";
import Team from "../components/team/team";
import { useTranslation } from "react-i18next";
import About from "../components/about/about";

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <div className="about-page">
      <PageHeader
        breadcrumbs={[
          { name: t("nav.home"), url: "/", key: "home" },
          { name: t("nav.about"), active: true, key: "about" },
        ]}
        title={t("about.title")}
      />
      <About />
      <Historique />
      <Objectives />
      <Team />
      <div className="d-flex justify-content-center my-4">
        <a className="btn btn-outline-primary py-2 px-3" href="/contact">
          {t("contactUs")}
          <div className="d-flex justify-content-center align-items-center btn-sm-square bg-primary text-white rounded-circle ms-2">
            <i className="fa fa-arrow-right"></i>
          </div>
        </a>
      </div>
    </div>
  );
};

export default AboutPage;
