import { useEffect } from "react";
import Parallax from "parallax-js";
import carousel2 from "../../../img/carousel-2.jpg";

const Donate = () => {
  // useEffect(() => {
  //   const container = document.querySelector(".donate");
  // }, []);

  return (
    <div className="container-fluid donate my-5 py-5">
      <div className="container py-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
              Agir avec nous
            </div>
            <h1 className="display-6 text-white mb-5 fw-bold">
              POURQUOI NOUS SOUTENIR
            </h1>
            <p className="text-white-50 mb-0">
              Votre don à l'Ong Agir pour l'Education en Afrique, permettra à
              chaque enfant né en Afrique d'avoir accès à l'éducation. Nous vous
              remercions pour ce geste noble!
            </p>
          </div>
          <div
            className="col-lg-4 col-md-6 col-12 wow fadeIn"
            data-wow-delay="0.5s"
          >
            <div className="h-100 p-5">
              <form>
                <div className="row g-3">
                  <div className="col-12">
                    <a
                      href="/donate"
                      className="btn btn-primary px-5 btn-submit-donate cursor-pointer"
                    >
                      Faire un don
                      <div className="d-flex justify-content-center align-items-center btn-sm-square bg-white text-primary rounded-circle ms-2">
                        <i className="fa fa-arrow-right"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donate;
