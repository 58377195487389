import PageHeader from "../components/pageHeader/pageheader";

const AgriEcole = () => {
  return (
    <div className="portefolio-page">
      <PageHeader
        breadcrumbs={[
          { name: "Accueil", url: "/", key: "home" },
          { name: "Activités", url: "/activities", key: "activities" },
          { name: "AgriÉcole", active: true, key: "agriecole" },
        ]}
        title="AgriÉcole"
      />
      <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4 mx-5">
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Contexte
        </div>
        <p className="text-dark mb-2">
          Le projet agri-école est un projet qui vise à créer des fermes et
          plantations agricoles afin de financer les activités de l’Ong.
        </p>
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Les Objectifs
        </div>
        <ul className="text-dark mb-2">
          <li>Permettre à l’ong d’être autonome </li>
          <li>Avoir des plantations ou des fermes agricoles </li>
          <li>Valoriser les produits agricoles et du terroir.</li>
          <li>Promouvoir l’agriculture chez les élèves</li>
        </ul>
      </div>
    </div>
  );
};

export default AgriEcole;
