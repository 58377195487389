import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";

import PageHeader from "../components/pageHeader/pageheader";

import { EMAIL_ADDRESS } from "../../utilities/infos";

const ContactPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let tmo;
    if (showAlert) {
      tmo = setTimeout(() => {
        setShowAlert(false);
      }, 500);
    }

    return clearTimeout(tmo);
  }, [showAlert]);

  const sendMessage = async (ev) => {
    // Will need a BE for this feature
    ev.preventDefault();
    try {
      setLoading(true);
      setShowAlert(false);
      const payload = {
        name,
        subject,
        from: email,
        text: message,
      };

      const params = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(
        `${process.env.REACT_APP_DOMAIN || ""}/api/contact`,
        params
      );
      if (response.status === 200) {
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setShowAlert(true);
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-page">
      <PageHeader
        breadcrumbs={[
          { name: "Accueil", url: "/", key: "home" },
          { name: "Contact", active: true, key: "contact" },
        ]}
        title="Contact"
      />
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 offset-lg-3 wow fadeIn"
              data-wow-delay="0.1s"
            >
              <div className="text-black d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
                Nous contacter
              </div>
              <h1 className="text-black display-6 mb-5">
                Une question, une remarque, une suggestion ?
              </h1>
              <p className="text-black mb-4">
                Vous êtes bénévole et souhaitez participer à une activité, vous
                voulez apporter un soutien financier, vous avez une demande
                particulière, une suggestion, vous pouvez nous joindre en
                remplissant ce formulaire.{" "}
              </p>
              <p className="text-black fw-700">Laissez nous votre message</p>
              <form>
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Votre nom"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <label htmlFor="name">Votre nom</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Votre e-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label htmlFor="email">Votre e-mail</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        placeholder="Objet"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                      <label htmlFor="subject">Objet</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control msg-area"
                        placeholder="Votre message ici"
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-center">
                    <button
                      onClick={sendMessage}
                      className="btn btn-primary py-2 px-3 me-3"
                      disabled={loading}
                    >
                      {loading ? "En cours ..." : "Envoyer"}
                      <div className="d-flex align-items-center justify-content-center btn-sm-square bg-white text-primary rounded-circle ms-2">
                        <i className="fa fa-arrow-right"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
              {showAlert && (
                <Alert
                  variant={"success"}
                  dismissible
                  onClose={() => setShowAlert(false)}
                >
                  Votre message à bien été envoyé
                </Alert>
              )}
            </div>
            {/* <div
            className="col-lg-6 wow fadeIn"
            data-wow-delay="0.5s"
            style="min-height: 450px;"
          >
            <div className="position-relative rounded overflow-hidden h-100">
              <iframe
                className="position-relative w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                frameborder="0"
                style="min-height: 450px; border:0;"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
