import PageHeader from "../components/pageHeader/pageheader";

const MentionsLegales = () => {
  return (
    <div className="mentions-page">
      <PageHeader
        breadcrumbs={[
          { name: "Accueil", url: "/", key: "home" },
          { name: "Mentions Légales", active: true, key: "cgus" },
        ]}
        title="Mentions Légales"
      />
      <div className="mx-md-5 px-md-5 px-2 text-black ">
        <p className="mb-2">CE SITE EST ÉDITÉ PAR :</p>
        <p className="mb-2">Agir Pour l'Education en Afrique (APEA)</p>
        <p className="mb-2">6 RUE GEORGES BERNANOS</p>
        <p className="mb-2">78500 SARTROUVILLE, FRANCE</p>
        <p className="mb-2">SIRET : 91935037100010</p>
        <p className="mb-2">
          Email :{" "}
          <a href="mailto:contact@agireducationafrique.com">
            contact@agireducationafrique.com
          </a>{" "}
        </p>
        <p className="mb-2 mt-3">
          Directeur de la publication : GODI Jean François Frédéric{" "}
        </p>
        <p className="mb-2">
          Prestataire d’hébergement du site est : HOSTINGER, 61 rue Lordou
          Vironos, 6023 Larnaca, Chypre
        </p>
        <p className="my-3 large-size-2 fw-700">
          I. DONNÉES NOMINATIVES – DROIT D’ACCÈS ET DE RECTIFICATION
        </p>
        Les données personnelles que vous avez communiquées à Agir Pour
        l’Education en Afrique APEA par l’intermédiaire du site internet
        https://www.agireducationafrique.com sont, sauf demande contraire de
        votre part, traitées et conservées par notre association. Conformément à
        la réglementation française et européenne en vigueur, vous pouvez vous
        opposer à l’utilisation de vos données ou y accéder pour leur
        rectification, limitation ou effacement en adressant une demande écrite,
        accompagnée d’un titre d’identité, à : Agir Pour l’Education en Afrique
        APEA par email au{" "}
        <a href="mailto:contact@agireducationafrique.com">
          contact@agireducationafrique.com
        </a>
        . Pour toute précision sur la politique de protection des données
        personnelles d’Agir Pour l’Education en Afrique APEA, rendez-vous sur la
        page Données personnelles.
        <p className="my-3 large-size-2 fw-700">
          II. CONDITIONS D’UTILISATION DU SITE
        </p>
        En accédant et/ou en utilisant le présent Site internet accessible à
        l’adresse https://www.agireducationafrique.com (ci-après « le Site »),
        tout utilisateur accepte, tacitement et sans réserve, les présentes
        conditions d’utilisation du Site.
        <p className="my-3 large-size-3 fw-700">1. PROPRIÉTÉ INTELLECTUELLE</p>
        <p className="mb-2">
          1.1. Le Site et chacun des éléments qui le compose, et notamment les
          textes, articles, lettres d’information, brochures, plans, nuage de
          mots-clés, images, illustrations, photographies, bases de données,
          logiciels, marques, logos, fichiers disponibles en téléchargement,
          clips vidéo ou sonores, structure générale du site, ci-après les
          Éléments de propriété intellectuelle, sont protégés par le droit
          français de la propriété intellectuelle. Les marques et logos
          reproduits sur le site internet sont déposés et protégés à titre de
          marque.
        </p>
        <p className="mb-2">
          1.2. Tous les droits de reproduction sont réservés pour tous les
          éléments de propriété intellectuelle du présent site internet. La
          reproduction totale ou partielle des Éléments de propriété
          intellectuelle est interdite.
        </p>
        <p className="mb-2">
          1.3. L’utilisateur n’est autorisé qu’à représenter les pages du Site
          sur son écran et n’est autorisé à reproduire les Éléments de propriété
          intellectuelle du Site qu’à titre temporaire aux seules fins de
          consultation du Site.
        </p>
        <p className="mb-2">
          1.4. L’utilisateur n’est autorisé qu’à télécharger et reproduire de
          manière durable, sur tout support, les publications d’Agir Pour
          l'Education en Afrique, et notamment les lettres d’information,
          lorsque cette reproduction ou représentation est faite à des fins
          strictement personnelles, privées et non commerciales. L’utilisateur
          s’interdit de communiquer ces publications à des tiers, y compris par
          courrier électronique et par mise à disposition à partir d’un site
          internet ou d’un serveur.
        </p>
        <p className="mb-2">
          1.5. L’utilisateur est autorisé à faire des courtes citations du Site,
          sous réserve que soit précisé de manière apparente : l’origine du
          document (i.e. « extrait du site d’Agir Pour l'Education en Afrique
          APEA ») le titre du document ou de la page cité(e), le nom de l’auteur
          et la date du document s’ils sont connus.
        </p>
        <p className="my-3 large-size-3 fw-700">2. UTILISATION DU SITE</p>
        <p className="mb-2">2.1 DISPOSITIONS GÉNÉRALES</p>
        <p className="mb-2">
          2.1.1. L’ensemble des données, informations et publications
          accessibles, représentées ou téléchargeables à partir du Site n’ont
          aucun caractère exhaustif, sont communiquées à titre purement
          informatif.
        </p>
        <p className="mb-2">
          2.1.2. Agir Pour l'Education en Afrique APEA ne pourra en aucun cas
          être tenu responsable de tout dommage direct ou indirect résultant de
          l’utilisation par tout utilisateur des données, informations ou
          publications accessibles ou téléchargeable à partir du Site.
        </p>
        <p className="mb-2">
          2.1.3. Agir Pour l'Education en Afrique APEA ne pourra en aucun cas
          être tenu responsable au titre du contenu accessible à partir des
          liens hypertextes publiés sur le Site ou des fichiers téléchargeables
          à partir du Site ou des sites accessibles à partir des liens
          hypertextes publiés sur le Site.
        </p>
        <p className="mb-2">
          2.1.4. La création et la publication d’un lien hypertexte vers l’une
          des pages du Site sont autorisées, sous réserve de l’accord préalable
          et exprès d’Agir Pour l'Education en Afrique APEA. La publication
          devra mentionner la source avec le lien hypertexte qui pointe sur le
          contenu du site. Toute utilisation à des fins commerciales est exclue.
        </p>
        <p className="mb-2">2.2 COOKIES</p>
        <p className="mb-2">
          Le site www.agireducationafrique.com utilise des « cookies » qui sont
          des fichiers texte (.txt) placés sur votre ordinateur.
        </p>
        <p className="mb-2">2.2.1. Politique d’utilisation des cookies</p>
        <p className="mb-2">
          Le site utilise 4 types de cookies : Les cookies techniquement
          nécessaires au bon fonctionnement des sites web. Ces cookies
          permettent notamment d’accéder aux espaces réservés et personnels des
          sites.
        </p>
        <p className="mb-2">
          Les cookies utilisés pour mesurer et analyser le comportement des
          internautes lors de la navigation. Ces cookies permettent d’établir
          des statistiques sur la fréquentation et l’utilisation des divers
          éléments composant le Site (rubriques et contenus visités, parcours),
          lui permettant ainsi d’améliorer l’intérêt et l’ergonomie de ses
          contenus.
        </p>
        <p className="mb-2">
          Les cookies utilisés par le service de partage de pages sur les
          réseaux sociaux. Ces cookies permettent notamment de mesurer l’usage
          qui est fait des différents boutons de partage du contenu qu’Agir Pour
          l’Education en Afrique APEA propose.
        </p>
        <p className="mb-2">
          Les cookies liés aux opérations relatives à la publicité. Ces cookies
          permettent d’afficher, en temps réel, le contenu le plus adapté aux
          centres d’intérêt des internautes déduits de leur navigation récente
          sur le Site. Pour tous ces types de cookies, Agir Pour l’Education en
          Afrique APEA s’engage à ne pas y conserver de données personnelles.
        </p>
        <p className="mb-2">
          2.2.2. Comment vous pouvez gérer les cookies qui sont déposés sur
          votre ordinateur ?
        </p>
        <p className="mb-2">
          L’enregistrement d’un cookie est subordonné à la volonté de
          l’utilisateur, que celui-ci peut exprimer et modifier à tout moment à
          travers des options de paramétrage offerts par son logiciel de
          navigation.
        </p>
        <p className="mb-2">
          Si vous avez accepté, dans votre logiciel de navigation,
          l’enregistrement de cookies par votre navigateur, les cookies intégrés
          dans les pages et contenus que vous avez consultés pourront être
          stockés temporairement dans un espace dédié. Ils y seront lisibles
          uniquement par leur émetteur. Si vous refusez l’enregistrement de
          cookies dans votre terminal, ou si vous supprimez ceux qui y sont
          enregistrés, vous ne pourrez plus bénéficier des fonctionnalités
          permises par ces cookies. Par exemple, il ne vous serait plus possible
          d’accéder aux espaces privés sécurisés par un mot de passe mémorisé.
          Vous devrez ainsi ressaisir vos mots de passe à chaque connexion.
        </p>
        <p className="mb-2">
          2.2.3. Comment configurer les cookies sur votre navigateur ? Vous
          pouvez configurer votre navigateur de manière à ce que des cookies y
          soient enregistrés ou, à l’inverse, qu’ils soient rejetés, soit
          systématiquement, soit selon leur émetteur. Vous pouvez également
          configurer votre navigateur de manière à ce que l’acceptation ou le
          refus des cookies vous soient proposés ponctuellement, avant qu’un
          cookie soit susceptible d’être enregistré. La configuration des
          cookies est propre au navigateur que vous utilisez. Elle est décrite
          dans le menu d’aide de votre navigateur, qui vous permettra de savoir
          de quelle manière modifier vos souhaits en matière de cookies.
        </p>
        <p className="my-3 large-size-2 fw-700">III. AVERTISSEMENT</p>
        AGIR POUR L’EDUCATION EN AFRIQUE APEA ne peut garantir la disponibilité
        et l’accessibilité permanente au Site, notamment pour des raisons
        techniques de maintenance. De même, elle ne pourra être tenue pour
        responsable des dysfonctionnements ci- après, sans que la liste soit
        limitative : La mauvaise transmission et/ou réception de toute donnée
        et/ou information sur Internet, Une intrusion extérieure ou la présence
        de virus informatique, La défaillance de tout matériel de réception ou
        des lignes de communication, Tout autre dysfonctionnement du réseau
        Internet empêchant le bon fonctionnement du Site.
        <p className="my-3 large-size-2 fw-700">
          IV. DROIT APPLICABLE ET LITIGES
        </p>
        Les règles d’utilisation du présent Site sont soumises à la loi
        française, ainsi que les éventuels litiges pouvant en découler.
      </div>
    </div>
  );
};

export default MentionsLegales;
