import ObjectiveItem from "./objectiveItem";
import serviceIcon1 from "../../../img/icon-1.png";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center objective mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
            {t("objectives.whatWeDo")}
          </div>
          <h1 className="display-6 mb-5 fw-bold text-dark">
            {t("objectives.title1")}
          </h1>
        </div>
        <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
          <p className="text-dark mb-2">
            L'ONG APEA a pour objectif de mener des actions dans le domaine de
            l’éducation en Afrique. Elle vise à permettre à chaque enfant
            d'avoir accès à une éducation de qualité et à rassembler toutes les
            personnes souhaitant œuvrer pour une amélioration du système
            éducatif en Afrique.
          </p>
          <p className="text-dark my-3 large-size-2 fw-700">
            Nos principales missions sont :
          </p>
          <ul>
            <li>Alphabétisation</li>
            <li>Éducation</li>
            <li>Scolarisation</li>
            <li>Excellence</li>
          </ul>
        </div>
        <div className="row g-4 justify-content-center">
          <ObjectiveItem
            title="Alphabétisation"
            icon={serviceIcon1}
            description="Lutter contre l’analphabétisme"
            href="/activities#activities-coming"
          />
          <ObjectiveItem
            title="Éducation"
            icon={serviceIcon1}
            description="Lutter contre les inégalités sociales à travers l'éducation."
            href="/activities#activities-coming"
          />
          <ObjectiveItem
            title="Scolarisation"
            icon={serviceIcon1}
            description="Promouvoir la scolarisation des filles"
            href="/activities#activities-coming"
          />
          <ObjectiveItem
            title="Excellence"
            icon={serviceIcon1}
            description="Promouvoir l’excellence à l’école"
            href="/activities#activities-coming"
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
