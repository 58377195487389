import PageHeader from "../components/pageHeader/pageheader";

const RessourcesPage = () => {
  return (
    <div className="ressources-page ">
      <PageHeader
        breadcrumbs={[
          { name: "Accueil", url: "/", key: "home" },
          { name: "Ressources", active: true, key: "ressources" },
        ]}
        title="Ressources"
      />
      <div className="container-xxl my-5 py-5">
        <div className="container ressources-page--title">
          <div className="mt-4 text-center mx-auto">
            <h2 className="large-size-6 fw-700 text-primary mb-2">
              Ressources pédagogiques
            </h2>
            <p className="text-black large-size-2">
              Retrouvez un ensemble de liens utiles et pédagogiques pour aider à{" "}
              <span className="text-black fw-700">
                {" "}
                l'apprentissage et à l'amélioration de compténces dans
                différents domaines
              </span>{" "}
            </p>
          </div>
        </div>
        <div className="container ressources-page--content my-5">
          <div className="bg-light rounded p-4">
            <p className="mb-3">
              <a
                href="https://www.logicieleducatif.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Jeux Éducatifs
              </a>{" "}
              pour améliorer votre niveau dans plusieurs disciplines
              (Mathématique, orthographe, anglais….)
            </p>
            <p className="mb-3">
              <a
                href="https://www.lelivrescolaire.fr/"
                target="_blank"
                rel="noreferrer"
              >
                Différents manuels
              </a>{" "}
              en ligne dans toutes les matières correspondants au programme
              français
            </p>
            <p className="mb-3">
              <a
                href="http://www2.mathkang.org/default.html"
                target="_blank"
                rel="noreferrer"
              >
                Des sujets du concours de mathématiques
              </a>{" "}
              pour tous les niveaux qui se déroule en France, chaque année.
            </p>
          </div>
        </div>
        {/* <div className="container ressources-page--title">
          <div className="mt-4 text-center mx-auto">
            <h2 className="large-size-6 fw-700 text-primary mb-2">
              Rapports et documents
            </h2>
            <p className="text-black large-size-2">
              autour de l'ecosysteme de l'éducation et la scolarisation
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default RessourcesPage;
