import PageHeader from "../components/pageHeader/pageheader";

const DonatePage = () => {
  return (
    <div className="donate-page">
      <PageHeader
        breadcrumbs={[
          { name: "Accueil", url: "/", key: "home" },
          { name: "Faire un don", active: true, key: "donate" },
        ]}
        title="Faire un don"
      />
      <div className="donate-page--form">
        <iframe
          className="donate-page--form-iframe"
          title="formulaire-don"
          allowtransparency="true"
          scrolling="auto"
          src="https://www.helloasso.com/associations/agir-pour-leducation-en-afrique/formulaires/1/widget"
          onload="window.scroll(0, this.offsetTop)"
        ></iframe>
      </div>
    </div>
  );
};

export default DonatePage;
