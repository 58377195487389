const FilInfo = (props) => {
  return (
    <div className="fil-info">
      <a href="/activities/olympiade">
        <div className="info text-primary fw-700">{props.text}</div>
      </a>
    </div>
  );
};

export default FilInfo;
