import { useTranslation } from "react-i18next";
import biblio2022 from "../../../img/biblio/2022/biblio-1.jpeg";

const DonLivre = ({ showGallery }) => {
  const { t } = useTranslation();
  return (
    <div id="olympiade" className="activities-pages--item">
      <h4 className="mt-5 mb-3 fw-700 text-dark">{t("activities.donLivre")}</h4>
      <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Motivations
        </div>
        <p className="text-dark mb-2">
          Le projet « la micro-bibliothèque pour mon village », a été initié par
          notre volonté de :
        </p>
        <p className="text-dark mb-2">Rapprocher les populations des livres</p>
        <p className="text-dark mb-2">Promouvoir la lecture</p>
        <p className="text-dark mb-2">Lutter contre l'analphabétisme</p>

        <p className="text-dark mb-2">
          Elle vise à équiper chaque village du département de Bocanda d'une
          micro bibliothèque de 100 livres.
        </p>

        <p className="text-dark mb-2">
          La première phase a permis d'octroyer plus de 5 mille livres aux
          bibliothèques de Bocanda, grâce à notre partenaire, Le bouquin Volant.
          Ce sont des manuels de tous genres et de tous niveaux (Manuels
          scolaires, romains…).
        </p>

        <h4 className="mt-5 mb-2 fw-bold text-dark text-primary">
          Edition 2021
        </h4>
        <p className="text-dark mb-2">
          Les livres ont été remis par le Président Marius KOFFI au préfet de
          Bocanda, le 07 Août 2021, lors de la célébration de la fête de
          l'indépendance à Bocanda.
        </p>

        <h4 className="mt-5 mb-2 fw-bold text-dark text-primary">
          Edition 2022
        </h4>
        <p className="text-dark mb-2">
          La deuxième phase a permis d'octroyer plus de 500 livres aux
          bibliothèques de N'zêcrêzêssou, sous-préfecture de Bocanda. Les
          bouquins ont été remis, par le président Marius KOFFI à la
          sous-préfete le 17 août 2022 à N'zêcrêzêssou.
        </p>

        <p className="text-dark mb-2">
          Toute notre gratitude au bouquin volant qui nous fournit les livres
        </p>

        <div className="preview-gallery w-100">
          <div className="preview-container">
            <img src={biblio2022} alt="preview-g" className="preview-img" />
            {/* <div className="preview-container--overlay">
              <a
                className="btn btn-outline-primary"
                onClick={(e) => {
                  e.preventDefault();
                  showGallery("/biblio");
                }}
                href="#images"
              >
                Les éditions en images
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonLivre;
