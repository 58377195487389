import PageHeader from "../components/pageHeader/pageheader";

const Elle = () => {
  return (
    <div className="portefolio-page">
      <PageHeader
        breadcrumbs={[
          { name: "Accueil", url: "/", key: "home" },
          { name: "Activités", url: "/activities", key: "activities" },
          {
            name: "Projet “Elle”",
            active: true,
            key: "educ-cine",
          },
        ]}
        title="Projet “Elle”"
      />
      <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4 mx-5">
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Contexte
        </div>
        <p className="text-dark mb-2">
          Aujourd'hui, de nombreuses jeunes filles manquent de modèle de
          réussite en Afrique. Elles ont pour modèles des faiseurs de buzz et
          des influenceuses, qui en réalité font la promotion de « l'argent
          facile ». Ce manque de modèle conduit de nombreuses jeunes filles à
          abandonner l'école. L'école n'a plus de sens ni d'importance à leurs
          yeux. Alors au travers le projet « journée elle », nous voulons
          sensibiliser nos jeunes sœurs.
        </p>
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Les Objectifs
        </div>
        <ul className="text-dark mb-2">
          <li>Mettre en place du tutorat pour les jeunes filles</li>
          <li>Lutter contre les grossesses en milieu scolaire</li>
          <li>Valoriser les modèles de réussite féminins</li>
          <li>Promouvoir l'excellence chez la jeune fille</li>
        </ul>
      </div>
    </div>
  );
};

export default Elle;
