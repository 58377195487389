import TeamMember from "./teamMember";
import member1 from "../../../img/team-1.jpg";
import member2 from "../../../img/team-2.jpg";
import member3 from "../../../img/team-3.jpeg";
import member4 from "../../../img/team-4.jpg";
import member5 from "../../../img/team-5.jpeg";

const Team = () => {
  return (
    <div className="container-xxl bg-light py-5">
      <div className="container team">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp team-title-container"
          data-wow-delay="0.1s"
        >
          <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
            L'équipe
          </div>
          <h1 className="display-6 mb-5 fw-bold text-dark">
            Faites la connaissance de nos avengers de l'éducation
          </h1>
        </div>
        <div className="row g-4">
          <div className="col-12 wow fadeInUp row" data-wow-delay="0.1s">
            <TeamMember
              fullName="Marius Koffi"
              designation="Président"
              photo={member1}
              facebook="https://www.facebook.com/profile.php?id=100069665951742"
            />
            <div className="col-lg-8 col-md-6">
              Je suis Marius KOFFI, Ingénieur en Maitrise des Risques
              Industriels. J'ai co-fondé l'ONG Agir pour l'Éducation en Afrique,
              car, il m'est important de donner la chance que j'ai eue à chaque
              enfant qui naît en Afrique. Étant élève, j'ai vu nombreux de mes
              amis abandonner l'école . En classe de CP1, nous étions une
              vingtaine d'écoliers. Seuls deux ont pu franchir la classe de
              terminale. Nombreux ont abandonné l'école par fautes de moyens. Et
              aujourd'hui encore, de nombreux enfants naissent sans avoir accès
              à l'éducation. Je trouve cela injuste. Alors, j'ai décidé de
              m'engager afin de permettre à chaque enfant d'avoir accès à
              l'éducation et réaliser ses rêves. Ayant bénéficié d'une bourse de
              l'État de Côte d'Ivoire pour poursuivre mes études à l'extérieur,
              je veux m'engager et encourager mes cadets et cadettes à emprunter
              la voie de l'excellence.
            </div>
          </div>
          <div className="row g-4">
            <div className="col-12 wow fadeInUp row" data-wow-delay="0.1s">
              <TeamMember
                fullName="Dramane Tangara"
                designation="Sécrétaire Général"
                photo={member2}
                linkedin="https://www.linkedin.com/in/dramane-tangara"
              />
              <div className="col-lg-8 col-md-6">
                Je suis ingénieur en mathématiques appliquées et travaille en
                tant qu'analyste de données dans une grande banque européenne.
                Venant d'une famille modeste, j'ai eu la chance d'aller à
                l'école publique en Côte d'Ivoire de l'école primaire à la fin
                du lycée puis d'être boursier de l'État de Côte d'Ivoire pendant
                toute la durée de mes études supérieures. Mon engagement au sein
                de l'association Agir Pour l'Education en Afrique a pour
                objectif principal d'encourager et accompagner les jeunes
                africains, élèves du premier et du second cycle notamment, qui
                souhaitent améliorer leur situation ainsi que celle de leur
                continent à travers l'éducation. Ainsi leur offrir la
                possibilité d'accéder à une éducation de qualité dans un
                environnement sein et qui promeut l'excellence à l'école est ce
                qui me pousse à m'investir au sein de APEA.
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-12 wow fadeInUp row" data-wow-delay="0.1s">
              <TeamMember
                fullName="Wilfried N'Don"
                designation="Chargé des Stratégies et projets"
                photo={member4}
                linkedin="https://www.linkedin.com/in/jean-pierre-wilfried-n-don-032197142/"
              />
              <div className="col-lg-8 col-md-6">
                Ensemble bâtissons l'avenir des leaders de demain
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-12 wow fadeInUp row" data-wow-delay="0.1s">
              <TeamMember
                fullName="Ahmed Simaga"
                designation="Chargé de la communication et relations extérieures"
                photo={member5}
                linkedin="https://www.linkedin.com/in/ahmed-simaga-524b18aa/"
              />
              <div className="col-lg-8 col-md-6">
                Je m'appelle Ahmed SIMAGA, Trader Obligataire à la salle de
                marchés de la SIB, Statisticien-économiste de formation. Je suis
                de la promotion AS34. Pour moi l'éducation est plus qu'une
                nécessité pour notre pays et surtout notre continent. Il
                faudrait qu'on puisse imprégner à la jeune génération cette
                envie d'apprendre et se surpasser peu importe le domaine. À
                travers APEA, notre but est de promouvoir l'excellence,
                participer à la culture et l'éducation de la jeune génération et
                nous-même nous former et éduquer au fur et à mesure car on ne
                finit jamais d'apprendre. Je suis plus que ravi d'être un
                ambassadeur de la promotion de l'excellence. J'apporterai ma
                petite pierre à l'édifice afin que la jeunesse africaine soit vu
                sous l'angle de l'excellence à tout niveau.
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-12 wow fadeInUp row" data-wow-delay="0.1s">
              <TeamMember
                fullName="Frédéric Godi"
                designation="Trésorier"
                photo={member3}
                linkedin="https://www.linkedin.com/in/fr%C3%A9d%C3%A9ric-godi-033406aa/"
              />
              <div className="col-lg-8 col-md-6">
                Quel que soit notre point de départ, nous avons tous droit au
                bonheur. Dans le système qui régit notre société actuelle,
                l'ouverture au bonheur, à une vie agréable n'est possible que
                par l'éducation et l'instruction. Je crois qu'en étant conscient
                de l'importance d'une bonne instruction et en ayant accès aux
                infrastructures la permettant, nous pouvons changer notre
                condition indépendemment de notre origine, notre situation de
                départ. J'ai à cœur de contribuer à mon échelle à l'amélioration
                des conditions de vie de la future génération d'africains, et
                c'est cela qui soutient mon engagement pour APEA.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
