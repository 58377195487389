import { useEffect } from "react";

const Spinner = ({ props }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log("remove spinner");
      document.querySelector("#spinner").classList.remove("show");
    }, 500);

    return clearTimeout(timeout);
  }, []);

  return (
    <div
      id="spinner"
      className="bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <div className="spinner-grow text-primary" role="status"></div>
    </div>
  );
};

export default Spinner;
