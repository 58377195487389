import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHref } from "react-router-dom";

import FilInfo from "../../components/filinfo/filinfo";

import applogo from "../../../img/logo.png";

import {
  QG_ADDRESS,
  EMAIL_ADDRESS,
  FACEBOOK_LINK,
  TWITTER_LINK,
  LINKEDIN_LINK,
  INSTAGRAM_LINK,
} from "../../../utilities/infos";

const NavBar = () => {
  const { t } = useTranslation();
  const href = useHref();

  const [active, setActive] = useState("home");

  useEffect(() => {
    console.log(href);
    const hrefLink = href.replace("/", "");
    setActive(hrefLink || "home");
  }, [href]);

  return (
    <div
      className="container-fluid fixed-top px-0 wow fadeIn shadow"
      data-wow-delay="0.1s"
    >
      <FilInfo text="Olympiade de l'Excellence - De Février à Mai 2023 - Bocanda et Yamoussoukro" />
      <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
        <div className="col-lg-6 px-5 d-flex">
          <small className="d-flex align-items-center">
            <i className="fa fa-map-marker-alt me-2"></i>
            {QG_ADDRESS}
          </small>
          <small className="ms-4 d-flex align-items-center">
            <i className="fa fa-envelope me-2"></i>
            {EMAIL_ADDRESS}
          </small>
        </div>
        <div className="col-lg-6 px-5 text-end">
          <small>{t("nav.followUs")} </small>
          <a className="text-white-50 ms-3" href={FACEBOOK_LINK}>
            <i className="fab fa-facebook-f"></i>
          </a>
          <a className="text-white-50 ms-3" href={TWITTER_LINK}>
            <i className="fab fa-twitter"></i>
          </a>
          <a className="text-white-50 ms-3" href={LINKEDIN_LINK}>
            <i className="fab fa-linkedin-in"></i>
          </a>
          <a className="text-white-50 ms-3" href={INSTAGRAM_LINK}>
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>

      <nav
        className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <a href="/" className="navbar-brand ms-4 ms-lg-0">
          <img className="app-logo" src={applogo} alt="logo-1" />
        </a>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <a
              href="/"
              className={`nav-item nav-link ${
                active === "home" ? "active" : ""
              }`}
            >
              {t("nav.home")}
            </a>
            <a
              href="/about"
              className={`nav-item nav-link ${
                active === "about" ? "active" : ""
              }`}
            >
              {t("nav.about")}
            </a>
            <a
              href="/activities"
              className={`nav-item nav-link ${
                active === "activities" ? "active" : ""
              }`}
            >
              {t("nav.activities")}
            </a>
            <a
              href="/ressources"
              className={`nav-item nav-link ${
                active === "ressources" ? "active" : ""
              }`}
            >
              {t("nav.ressources")}
            </a>
            <a
              href="/adhesion"
              className={`nav-item nav-link ${
                active === "adhesion" ? "active" : ""
              }`}
            >
              {t("nav.adherer")}
            </a>
            <a
              href="/contact"
              className={`nav-item nav-link ${
                active === "contact" ? "active" : ""
              }`}
            >
              {t("nav.contact")}
            </a>
            <a
              href="/donate"
              className={`nav-item nav-link ${
                active === "donate" ? "active" : ""
              } d-lg-none d-block`}
            >
              Faire un don
            </a>
          </div>
          <div className="d-none d-lg-flex ms-2">
            <a className="btn btn-outline-primary py-2 px-3" href="/donate">
              Faire un don
              {/* <div className="d-flex align-items-center justify-content-center btn-sm-square bg-white text-primary rounded-circle ms-2">
                <i className="fa fa-arrow-right"></i>
              </div> */}
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
