//Composant pour l'affichage des missions
//Les liens "Learn more" doivent rediriger vers les pages des activités liés

import { t } from "i18next";
import { useTranslation } from "react-i18next";

// à la mission
const ServiceItem = (props) => {
  const { t } = useTranslation();
  return (
    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
      <div className="objective-item bg-white text-center h-100 p-4 p-xl-5">
        <img className="img-fluid mb-4" src={props.icon} alt="" />
        <h4 className="mb-3 text-dark fw-bold">{props.title}</h4>
        <p className="mb-4 objective-item--description">{props.description}</p>
        <a className="btn btn-outline-primary px-3" href={props.href}>
          {t("learnMore")}
          <div className="d-flex align-items-center justify-content-center btn-sm-square bg-primary text-white rounded-circle ms-2">
            <i className="fa fa-arrow-right"></i>
          </div>
        </a>
      </div>
    </div>
  );
};

export default ServiceItem;
