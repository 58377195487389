import PageHeader from "../components/pageHeader/pageheader";

const Restaurant = () => {
  return (
    <div className="portefolio-page">
      <PageHeader
        breadcrumbs={[
          { name: "Accueil", url: "/", key: "home" },
          { name: "Activités", url: "/activities", key: "activities" },
          {
            name: "Restaurant solidaire",
            active: true,
            key: "educ-cine",
          },
        ]}
        title="Restaurant solidaire"
      />
      <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4 mx-5">
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Contexte
        </div>
        <p className="text-dark mb-2">
          Aujourd'hui en Côte d'Ivoire, bon nombre d'élèves issus de familles
          défavorisées arrivent à avoir à peine un repas par jour.
        </p>
        <p className="text-dark mb-2">
          Nombreux de ces élèves abandonnent donc l'école par fautes de moyens.
          Dans certaines régions, le manque d'accès à la nourriture engendre de
          nombreux maux :
        </p>
        <ul className="text-dark mb-2">
          <li>Abandon de l'école</li>
          <li>Les grossesses en milieu scolaire chez les jeunes filles</li>
          <li>Baisse du niveau des résultats</li>
          <li>Le banditisme</li>
        </ul>
        <p className="text-dark mb-2">
          Face à ce triste constat, il faut trouver des moyens afin de permettre
          à chaque élève d'avoir au moins deux repas par jour. A cet effet,
          contrairement, aux cantines scolaires, nous proposons des
          retau-solidaires, afin de permettre à chaque élève d'avoir accès à la
          nourriture.
        </p>
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Les Objectifs
        </div>
        <p className="text-dark mb-2">
          Concrètement, les restau-solidaires visent les objectifs suivants
        </p>
        <ul className="text-dark mb-2">
          <li>Permettre à chaque élève d'avoir accès à la nourriture</li>
          <li>Lutter contre la déscolarisation</li>
          <li>Lutter contre les grossesses en milieu scolaire</li>
          <li>Lutter contre le banditisme</li>
          <li>Favoriser la réussite scolaire.</li>
        </ul>
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Fonctionnement
        </div>
        <p className="text-dark mb-2">
          Le restaurant solidaire est un restaurant qui permettra à chaque élève
          d'avoir accès à la nourriture en fonction de ses moyens. C'est une
          sorte de restaurant-cantine. Il sera constitué de deux parties : une
          partie restaurant une partie cantine. La partie restaurant est
          réservée aux clients et la partie cantine est réservée aux élèves. Les
          bénéfices générés par la partie restaurant permettront à la cantine de
          fonctionner.
        </p>
      </div>
    </div>
  );
};

export default Restaurant;
