import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import fr from "./translations/fr";

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  lng: "fr",
  fallbackLng: "fr",
  debug: false,
  resources: {
    fr,
  },

  // react i18next special options (optional)
  // override if needed - omit if ok with defaults
  /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
});

export default i18n;
