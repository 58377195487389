import { useTranslation } from "react-i18next";
import olympiade2021 from "../../../img/olympiade/2021/olympiade-1.jpeg";
import olympiade2022 from "../../../img/olympiade/2022/olympiade-1.jpeg";

const Olympiade = ({ showGallery }) => {
  const { t } = useTranslation();
  return (
    <div id="olympiade" className="activities-pages--item">
      <h4 className="mt-5 mb-3 fw-700 text-dark">
        {t("activities.olympiade")}
      </h4>
      <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          L'historique
        </div>
        <p className="text-dark mb-2">
          Les olympiades de mathématiques, de dictée et de culture générale, ont
          été créées suite à une baisse drastique du niveau scolaire en Côte
          d'Ivoire, en l'occurrence, à Bocanda, dans la région du N'zi. En
          effet, à l'issue des examens blancs des élèves de troisième, seulement
          0.96 % de candidats ont été déclarés. Face à ces résultats drastiques,
          certains membres de l'ONG, en l'occurrence Marius KOFFi et Dramane
          Tangara ont decidé d'agir rapidement et efficacement. C'est dans cette
          optique que sont nées les olympiades de Mathématiques, de dictée et de
          culture générale.
        </p>
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Les Objectifs
        </div>
        <p className="text-dark mb-2">
          Les objectifs visés par les olympiades sont : Créer une saine
          émulation entre les élèves et également entre les équipes
          pédagogiques; Encourager et amener les élèves à s'intéresser aux
          études; Promouvoir les mathématiques auprès des plus jeunes et surtout
          des jeunes filles ; Relever le niveau en orthographe et en grammaire
          des élèves ; Contribuer à réduire les charges des parents par la
          distribution des kits scolaires; Promouvoir la lecture et le calcul
          auprès des écoliers; Créer un environnement favorable aux études et à
          la réussite scolaire.
        </p>
        <h4 className="mt-5 mb-2 fw-bold text-dark text-primary">
          Edition 2021
        </h4>
        <p className="text-dark mb-2">
          La première édition des Olympiades s'est déroulée dans la ville de
          Bocanda. Elle a concerné uniquement les élèves de troisième des 4
          établissements secondaires de ladite ville (Lycée moderne de Bocanda,
          Collège Bopkli, Collège le Prestige et collège le Label). Au total 200
          élèves de troisième avaient participé à cette édition. 43 ont été
          admis pour la finale, et 10 ont été primés.
        </p>
        <p className="text-dark mb-2">
          Elle s'est déroulée en 3 phases : La présélection, la finale et la
          cérémonie. La cérémonie a eu lieu dans la cour de la préfecture de
          Bocanda. A cette édition, Le lycée de Bocanda a été le vainqueur par
          établissement et{" "}
          <span className="fstyle-italic fw-700">
            Mlle Kouadio Florence a été la Miss Olympiades et la lauréate
          </span>
        </p>
        <span className="my-3">Recompenses</span>
        <ul className="text-dark mb-2">
          <li>Des médailles d'or ont été decernés aux 3 premiers</li>
          <li>Les 3 suivants des médailles d'argent</li>
          <li>Les 4 derniers des médailles de bronze.</li>
          <li>
            Chaque lauréat est reparti avec un kit scolaire et une attestation
            de participation.{" "}
          </li>
          <li>Une tablette a été decernée au premier.</li>
        </ul>
        <div className="preview-gallery w-100">
          <div className="preview-container">
            <img src={olympiade2021} alt="preview-g" className="preview-img" />
            {/* <div className="preview-container--overlay">
              <a
                className="btn btn-outline-primary"
                onClick={(e) => {
                  e.preventDefault();
                  showGallery("/olympiade/2021");
                }}
                href="#images"
              >
                L'édition en images
              </a>
            </div> */}
          </div>
        </div>
        <h4 className="mt-5 mb-2 fw-bold text-dark text-primary">
          Edition 2022
        </h4>
        <p className="text-dark mb-2">
          La deuxième édition des olympiades 2022, s'est déroulée dans tout le
          departement de Bocanda dans la région du N'zi au Centre-Est de la Côte
          d'Ivoire. Contrairement à la première édition elle a concerné les
          élèves de 3e de tout le département de Bocanda et les élèves de CM2 de
          la ville de Bocanda.
        </p>

        <p className="text-dark mb-2">
          Au total, 9 établissements secondaires et 10 écoles primaires ont pris
          part à cette édition. Elle s'est déroulée également en 3 grandes
          phases de février à mai 2022. Les 3 phases sont : la présélection, la
          finale et la cérémonie.
        </p>

        <p className="text-dark mb-2">
          Elle est pilotée par{" "}
          <span className="fstyle-italic fw-700">Mlle Bernadette KOFFI</span>,
          sociologue de formation.
        </p>
        <p className="text-dark mb-2">
          La finale a eu lieu le 07 mai à la place de l'indépendance de Bocanda.
          Elle a vu la participation des autorités politiques, administratives,
          coutumières et éducatives de ladite ville. Elle a réuni plus de 1000
          personnes.
        </p>

        <p className="text-dark mb-2">
          Cette édition a vu le sacre de{" "}
          <span className="fstyle-italic fw-700">
            N'guessan Moaillet Christ Israel élève de l'EPP municipalité 2, pour
            le niveau CM2 et de Mlle DEPEUKA Manou Christ, élève au lycée
            moderne de Bocanda pour le niveau 3e.
          </span>
        </p>

        <p className="text-dark mb-2">
          La miss olympiades a été remportée par{" "}
          <span className="fstyle-italic fw-700">
            Mlle DEPEUKA Manou Christ
          </span>{" "}
          pour le niveau 3e et de{" "}
          <span className="fstyle-italic fw-700">
            Mlle DIAGOU Elvira Fatine
          </span>{" "}
          , élève de l'EPP CMA pour le niveau CM2
        </p>

        <p className="text-dark mb-2">
          Le premier prix par établissement a été remporté par l'EPP
          municipalité 2 pour le niveau CM2 et par le collège Le Prestige pour
          le niveau 3e.
        </p>

        <p className="text-dark mb-2">
          Plus de 500 élèves de 3e et plus de 90 écoliers de CM2 ont pris part à
          cette édition.
        </p>

        <p className="text-dark mb-2">
          Des médailles, des kits scolaires, des tablettes et des attestations
          de participation ont été decernés aux lauréats.
        </p>

        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Équipe
        </div>
        <p className="text-dark mb-2">
          Les olympiades ont été pilotées par une équipe de bénévoles très
          dynamique.
        </p>

        <ul className="text-dark mb-2 fstyle-italic fw-700 ">
          <li>
            Mlle KOFFI Ahou Bernadette, coordonnatrice principale et doctorante
            en sociologie
          </li>
          <li>MM. Konan Hervé</li>
          <li>Kouassi Frédéric</li>
          <li>Konan César</li>
          <li>Casimir Brou</li>
          <li>Marcellin Konan</li>
          <li>Mohamed Sidibé</li>
          <li>Olivier Téhoua </li>
          <li>Mlle Raissa Kouamé </li>
          <li>Mlle Marie-France.</li>
        </ul>

        <div className="preview-gallery w-100">
          <div className="preview-container">
            <img src={olympiade2022} alt="preview-g" className="preview-img" />
            {/* <div className="preview-container--overlay">
              <a
                className="btn btn-outline-primary"
                onClick={(e) => {
                  e.preventDefault();
                  showGallery("/olympiade/2022");
                }}
                href="#images"
              >
                L'édition en images
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Olympiade;
