export const QG_ADDRESS = "PARIS, FRANCE";

export const EMAIL_ADDRESS = "contact@agireducationafrique.com";

export const FACEBOOK_LINK =
  " https://www.facebook.com/profile.php?id=100088238700306";
export const TWITTER_LINK = "";
export const LINKEDIN_LINK = "";
export const INSTAGRAM_LINK =
  "https://www.instagram.com/apea_education_africa/";
