import PageHeader from "../components/pageHeader/pageheader";

const Educine = () => {
  return (
    <div className="portefolio-page">
      <PageHeader
        breadcrumbs={[
          { name: "Accueil", url: "/", key: "home" },
          { name: "Activités", url: "/activities", key: "activities" },
          { name: "Educ-Ciné", active: true, key: "educ-cine" },
        ]}
        title="Educ-Ciné"
      />
      <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4 mx-5">
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Contexte
        </div>
        <p className="text-dark mb-2">
          Aujourd'hui en Côte d'Ivoire, très peu d'élèves ont accès au cinéma.
          La majorité ont accès aux réseaux sociaux et à la télévision.
          Toutefois, les programmes proposés par les chaines de télévision sont
          assez nocifs pour les élèves. Dans les différents programmes, à peine
          1 % est consacré aux émissions éducatives. La majorité des programmes
          ne font la promotion des buzz et des faiseurs de buzz. À cet effet, la
          télévision, plutôt que d'être un moyen pour promouvoir l'excellence,
          elle devient un moyen pour désorienter les élèves. Alors à travers
          l'éduc-ciné, nous voulons réorienter les élèves vers l'excellence au
          travers le cinéma.
        </p>
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Les Objectifs
        </div>
        <p className="text-dark mb-2">
          Le projet éduc ciné consiste à organiser des séances de cinéma
          gratuitement en salle ou en plein air à l'endroit des élèves, des
          parents d'élèves et de l'équipe éducative. Il vise à
        </p>
        <ul className="text-dark mb-2">
          <li>
            Sensibiliser les élèves, les parents d'élèves et l'équipe éducative
            au travers le cinéma
          </li>
          <li>
            Promouvoir l'excellence et les valeurs de l'école par le cinéma
          </li>
          <li>
            Permettre aux élèves et écoliers en milieu rural et défavorisé
            d'avoir accès à la culture et au cinéma.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Educine;
