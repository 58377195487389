// import ProgressBar from "../progressbar/progressbar";

const CausesItem = (props) => {
  return (
    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
      <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
        <div className="text-center p-4 pt-0">
          <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4">
            <small>{props.category}</small>
          </div>
          <h5 className="mb-3 text-dark fw-bold">{props.title}</h5>
          <p>{props.description}</p>
          {/* <div className="causes-progress bg-light p-3 pt-2">
            <div className="d-flex justify-content-between">
              <p className="text-dark">
                {props.goal} <small className="text-body">Goal</small>
              </p>
              <p className="text-dark">
                {props.raised} <small className="text-body">Raised</small>
              </p>
            </div>
            <ProgressBar progress="90" />
          </div> */}
        </div>
        <div className="position-relative mt-auto">
          <img className="img-fluid" src={props.cover} alt="" />
          <div className="causes-overlay">
            <a className="btn btn-outline-primary" href={props.readmore}>
              En savoir plus
              <div className="d-flex justify-content-center align-items-center btn-sm-square bg-primary text-white rounded-circle ms-2">
                <i className="fa fa-arrow-right"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CausesItem;
