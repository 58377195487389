export function viewScrollEvent() {
  window.addEventListener("scroll", (ev) => {
    const topbar = document.querySelector(".fixed-top");

    const scrollTop = document.documentElement.scrollTop;

    if (window.innerWidth < 992) {
      if (scrollTop > 45) {
        topbar.classList.add("bg-dark");
        topbar.classList.add("shadow");
      } else {
        topbar.classList.remove("bg-dark");
        topbar.classList.remove("shadow");
      }
    } else {
      if (scrollTop > 45) {
        topbar.classList.add("bg-dark");
        topbar.classList.add("shadow");
        topbar.style.top = -45;
      } else {
        topbar.classList.remove("bg-dark");
        topbar.classList.remove("shadow");
        topbar.style.top = 0;
      }
    }
  });
}

// // Back to top button
// $(window).scroll(function () {
//     if ($(this).scrollTop() > 300) {
//         $('.back-to-top').fadeIn('slow');
//     } else {
//         $('.back-to-top').fadeOut('slow');
//     }
// });
// $('.back-to-top').click(function () {
//     $('html, body').animate({scrollTop: 0}, 1500, 'easeInOutExpo');
//     return false;
// });
