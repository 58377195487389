const fr = {
  translation: {
    nav: {
      followUs: "Suivez nous:",
      home: "Accueil",
      about: "Nous connaître",
      objectives: "Objectifs et Missions",
      history: "Historique",
      team: "L'équipe",
      activities: "Activités",
      ressources: "Ressources",
      contact: "Contact",
      adherer: "Adhérer",
      activitiesDone: "Activités réalisées",
      activitiesNext: "Activités à venir",
      cgus: "Mentions légales",
    },
    about: {
      title: "À propos de l'ONG",
      us: "Qui sommes nous",
      title1: "Nous oeuvrons pour l’amélioration de l’éducation en Afrique",
    },
    objectives: {
      whatWeDo: "Objectifs et Missions",
      title1: "Permettre à chaque enfant d'avoir accès à l’éducation",
    },
    histoire: {
      notre: "Notre inspiration",
      title1: "Histoire",
    },
    activities: {
      done: "Activités réalisées",
      coming: "Projets à venir",
      olympiade: "Les Olympiades de l'Excellence",
      donLivre: "Une bibliothèque pour mon village",
    },
    learnMore: "En savoir plus",
    contactUs: "Nous contacter",
  },
};

export default fr;
