import { useTranslation } from "react-i18next";
import about1 from "../../../img/about/about-1.jpeg";
import about2 from "../../../img/about/about-2.jpeg";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="position-relative overflow-hidden h-100 about-imgs-container">
              <img
                className="position-absolute w-100 h-100 pt-5 pe-5 img-fit-cover"
                src={about1}
                alt="about-1"
              />
              <img
                className="position-absolute top-0 end-0 bg-white ps-2 pb-2 img-top-over"
                src={about2}
                alt="about-2"
              />
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="h-100">
              <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
                {t("about.us")}
              </div>
              <h1 className="display-6 mb-5 fw-bold text-dark">
                {t("about.title1")}
              </h1>
              <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
                <p className="text-dark">
                  L'ONG Agir pour l’Éducation en Afrique a été créée, afin de
                  réunir les uns et les autres autour d’un bien commun :
                  l’éducation en Afrique.
                </p>
                <p className="text-dark mb-2">
                  On ne le dira jamais assez, l’éducation est la base de tout
                  développement. Notre rêve de voir l’Afrique développée, passe
                  nécessairement par l’éducation.
                </p>
                <span className="text-primary">
                  Marius Koffi, Ambassadeur de l'Excellence
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
