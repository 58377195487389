import LandingCarousel from "../components/landingCarousel/carousel";
import About from "../components/about/about";
import Activities from "../components/activities/activities";
// import Services from "../components/services/services";
import Donate from "../components/donate/donate";
import Team from "../components/team/team";
import EventsBanner from "../components/events/eventsBanner";
// import Testimonial from "../components/testimonial/testimonial";

const LandingPage = () => (
  <>
    <LandingCarousel />
    {/* <EventsBanner /> */}
    <About />
    <Activities />
    {/* <Services /> */}
    <Donate />
    <Team />
    {/* <Testimonial /> */}
  </>
);

export default LandingPage;
