import PageHeader from "../components/pageHeader/pageheader";

const Olympiade = () => {
  return (
    <div className="portefolio-page">
      <PageHeader
        breadcrumbs={[
          { name: "Accueil", url: "/", key: "home" },
          { name: "Activités", url: "/activities", key: "activities" },
          {
            name: "Olympiades de l'Excellence",
            active: true,
            key: "educ-cine",
          },
        ]}
        title="Olympiades de l'Excellence"
      />
      <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4 mx-5">
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Les Objectifs
        </div>
        <p className="text-dark mb-2">
          Les olympiades de Mathématiques, de dictée et de culture générale sont
          une compétition qui vise à
        </p>
        <ul className="text-dark mb-2">
          <li>Promouvoir l’excellence en milieu scolaire </li>
          <li>
            Créer une saine émulation entre les élèves et les établissements
            afin de créer un environnement général d’excellence
          </li>
          <li>
            Relever le niveau en mathématiques et en dictée chez les élèves
          </li>
          <li>
            Promouvoir l’excellence et les matières scientifiques chez la jeune
            fille
          </li>
          <li>Lutter contre les grossesses en milieu scolaire</li>
        </ul>
        <h4 className="mt-5 mb-2 fw-bold text-dark text-primary">
          Edition 2023
        </h4>
        <p className="text-dark mb-2">
          <span className="fw-700">Localité : </span> Yamoussoukro et Bocanda
        </p>
        <p className="text-dark mb-2 ms-3">
          Bocanda : Tout le département de Bocanda (Sous-préfectures de
          Kouadioblékro, (Sous-préfectures de Kouadioblékro, N’zêcrêzêssou,
          Bengassou, Kouadioblékro)
          <br />
          Yamoussoukro : Toute la ville de Yamoussoukro
        </p>
        <p className="text-dark mb-2 fw-700">Date :</p>
        <p className="text-dark mb-2 ms-3">Février à Mai 2023</p>
        <p className="text-dark mb-2 fw-700">Niveaux concernés :</p>
        <p className="text-dark mb-2 ms-3">
          Bocanda : CM2 et 3e <br />
          Yamoussoukro : 3e
        </p>
        <p className="text-dark mb-2 fw-700">Disciplines :</p>
        <p className="text-dark mb-2 ms-3">
          CM2 : Dictée, lecture et mathématiques
          <br />
          3e : Dictée, mathématiques et culture générale
        </p>
        <span className="my-3">Recompenses</span>
        <ul className="text-dark mb-2">
          <li>
            Prix par établissement : Des trophées pour les trois premiers
            établissements
          </li>
          <li>
            Prix individuels : Des ordinateurs portables, des kits scolaires,
            des médailles…
          </li>
          <li>Prix Miss olympiades : Des ordinateurs + couronne</li>
        </ul>
      </div>
    </div>
  );
};

export default Olympiade;
