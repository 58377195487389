import { useState } from "react";
import PageHeader from "../components/pageHeader/pageheader";

const TeamPage = () => {
  const [type, setType] = useState("");

  const sendForm = () => {};

  return (
    <div className="adhesion-page">
      <PageHeader
        breadcrumbs={[
          { name: "Accueil", url: "/", key: "home" },
          { name: "Adhésion", active: true, key: "adhesion" },
        ]}
        title="Adhésion"
      />
      <div className="container-xxl my-5 py-5">
        <div className="container adhesion-page--title">
          <div className="mt-4 text-center mx-auto">
            <h3>Vous souhaitez</h3>
            <h2 className="large-size-6 fw-700 text-primary mb-2">
              Nous rejoindre
            </h2>
          </div>
          <div className="mt-5 text-center mx-auto">
            <h5>
              Veuillez selectionner le type d'adhésion qui vous convient puis
              rejoignez-nous{" "}
            </h5>
          </div>
          <div className="mt-3 w-100 d-flex justify-content-center">
            <div
              className={`btn ${
                type === "actif" ? "btn-primary" : "btn-outline-primary"
              } p-2 mx-2`}
              onClick={() => setType("actif")}
            >
              Membre Actif
            </div>
            <div
              className={`btn ${
                type === "bienfaiteur" ? "btn-primary" : "btn-outline-primary"
              } p-2 mx-2`}
              onClick={() => setType("bienfaiteur")}
            >
              Membre Bienfaiteur
            </div>
          </div>
          <div className="mt-3 ">
            {/* {type === "actif" && <p></p>}
            {type === "bienfaiteur" && (
              <p>
                Est membre bienfaiteur toute personne physique ou morale qui
                verse un droit d'adhésion et se soumet à une cotisation annuelle
                d'un montant au choix (Minimum 10€)
              </p>
            )} */}
          </div>
          {type === "bienfaiteur" && (
            <div className="g-5 adhesion-page--form ">
              <iframe
                className="adhesion-page--form-iframe"
                title="form-adhesion-bienfaiteur"
                id="haWidget-bienfaiteur"
                allowtransparency="true"
                scrolling="auto"
                src="https://www.helloasso.com/associations/agir-pour-leducation-en-afrique/adhesions/adhesion-a-l-ong-apea-1/widget"
              ></iframe>
            </div>
          )}
          {type === "actif" && (
            <div className="g-5 adhesion-page--form ">
              <iframe
                className="adhesion-page--form-iframe"
                title="form-adhesion-actif"
                id="haWidget-actif"
                allowtransparency="true"
                scrolling="auto"
                src="https://www.helloasso.com/associations/agir-pour-leducation-en-afrique/adhesions/adhesion-membre-actif/widget"
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamPage;
