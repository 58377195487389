import PageHeader from "../components/pageHeader/pageheader";

const Biblio = () => {
  return (
    <div className="portefolio-page">
      <PageHeader
        breadcrumbs={[
          { name: "Accueil", url: "/", key: "home" },
          { name: "Activités", url: "/activities", key: "activities" },
          { name: "Don de livre", active: true, key: "biblio" },
        ]}
        title="Don de livre"
      />
      <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4 mx-5">
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Contexte
        </div>
        <p className="text-dark mb-2">
          A travers ce projet, nous voulons, rapprocher les populations, les
          élèves des livres en offrant ou équipant des villes et des écoles en
          livres.
        </p>
        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 my-3">
          Les Objectifs
        </div>
        <ul className="text-dark mb-2">
          <li>
            Dons de livres aux bibliothèques scolaires , communales ou
            régionales
          </li>
          <li>
            Offrir à chaque village une micro-bibliothèque d'au moins 100 livres
          </li>
          <li>Lutter contre l'analphabétisme</li>
          <li>Promouvoir la lecture</li>
        </ul>
      </div>
    </div>
  );
};

export default Biblio;
