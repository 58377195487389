import { useTranslation } from "react-i18next";
import {
  QG_ADDRESS,
  EMAIL_ADDRESS,
  FACEBOOK_LINK,
  TWITTER_LINK,
  LINKEDIN_LINK,
} from "../../../utilities/infos";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div
      className="container-fluid bg-dark text-white-50 footer mt-5 pt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-4 col-md-6">
            <h1 className="fw-bold text-primary mb-4">
              Ap<span className="text-white">e</span>a
            </h1>
            <p>Ensemble changeons le monde par l'éducation</p>
            <div className="d-flex pt-2">
              <a className="btn btn-square me-1" href={TWITTER_LINK}>
                <i className="fab fa-twitter"></i>
              </a>
              <a className="btn btn-square me-1" href={FACEBOOK_LINK}>
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btn btn-square me-0" href={LINKEDIN_LINK}>
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <h5 className="text-light mb-4 fw-bold">Adresse</h5>
            <p className="d-flex align-items-center">
              <i className="fa fa-map-marker-alt me-3"></i>
              {QG_ADDRESS}
            </p>
            <p className="d-flex align-items-center">
              <i className="fa fa-envelope me-3"></i>
              {EMAIL_ADDRESS}
            </p>
          </div>
          <div className="col-lg-4 col-md-6">
            <h5 className="text-light mb-4 fw-bold">Liens utiles</h5>
            <a className="btn btn-link" href="/about">
              {t("nav.about")}
            </a>
            <a className="btn btn-link" href="/contact">
              {t("nav.contact")}
            </a>
            <a className="btn btn-link" href="/activities">
              {t("nav.activities")}
            </a>
            <a className="btn btn-link" href="/adhesion">
              {t("nav.adherer")}
            </a>
            <a className="btn btn-link" href="/cgus">
              {t("nav.cgus")}
            </a>
          </div>
          {/* <div className="col-lg-4 col-md-6">
            <h5 className="text-light mb-4 fw-bold">Newsletter</h5>
            <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
            <div className="position-relative mx-auto newsletter-container">
              <input
                className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Your email"
              />
              <button
                type="button"
                className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
              >
                SignUp
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <div className="container-fluid copyright">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a href="#">APEA</a>, 2023 All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              Designed By <a href="https://htmlcodex.com">HTML Codex</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
