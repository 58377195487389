import { useState } from "react";
import { useTranslation } from "react-i18next";

import PageHeader from "../components/pageHeader/pageheader";
import Olympiade from "../components/olympiade/olympiade";
import DonLivre from "../components/donLivre/donLivre";
import ActivitiesItem from "../components/activities/activitiesItem";
import PopView from "../components/modal";

import olympiadeCover from "../../img/olympiade/cover.jpeg";
import biblioCover from "../../img/biblio/cover.jpeg";
import cineCover from "../../img/cinecover.jpg";
import agriCover from "../../img/agricover.jpg";
import restauCover from "../../img/restaucover.jpg";
import elleCover from "../../img/ellecover.jpg";
import educCover from "../../img/educcover.jpg";

const ActivitiesPage = () => {
  const { t } = useTranslation();
  const [showGallery, setShowGallery] = useState(false);
  const [galleryFolder, setFolder] = useState("");

  const displayGallery = (path) => {
    setShowGallery(true);
  };

  return (
    <div className="activities-page">
      <PageHeader
        breadcrumbs={[
          { name: "Accueil", url: "/", key: "home" },
          { name: "Activités", active: true, key: "activities" },
        ]}
        title="Activités"
      />
      <div className="container-xxl py-3">
        <div className="bg-light rounded p-4 mb-4">
          <p className="text-dark mb-2">
            Afin d'atteindre ses objectifs, l'Ong s'attelle à organiser des
            activités allant de la scolarisation de l'élève à son insertion
            professionnelle.
          </p>
          <p>Découvrez nos projets et activités</p>
        </div>
        <Olympiade showGallery={displayGallery} />
        <DonLivre showGallery={displayGallery} />
        <div id="activities-coming" className="content-title">
          <h3 className="mb-5 fw-700 text-dark text-center">
            {t("activities.coming")}
          </h3>
        </div>
        <div className="row g-4 justify-content-center">
          <ActivitiesItem
            category="Education"
            title="Olympiades de l'Excellence"
            description="Edition 2023"
            cover={olympiadeCover}
            readmore="/activities/olympiade"
          />
          <ActivitiesItem
            category="Don de livre"
            title="Une bibliothèque pour mon village"
            description="Edition 2023"
            cover={biblioCover}
            readmore="/activities/biblio"
          />
          <ActivitiesItem
            category="Education"
            title="Educ-ciné"
            description="Projection de film en salle ou en plein air dans les milieux ruraux pour sensibiliser à la scolarisation"
            cover={cineCover}
            readmore="/activities/educ-cine"
          />
          <ActivitiesItem
            category="Restauration"
            title="Restaurant solidaire"
            description="Permettre à chaque élève d'avoir accès à la nourriture"
            cover={restauCover}
            readmore="/activities/restau"
          />
          <ActivitiesItem
            category="Scolarisation"
            title="Projet “Elle“"
            description="Valoriser les modèles de réussite féminins et Promouvoir l'excellence chez la jeune fille"
            cover={elleCover}
            readmore="/activities/p-elle"
          />
          <ActivitiesItem
            category="Education"
            title="Agri-école"
            description="Créer des fermes et plantations agricoles"
            cover={agriCover}
            readmore="/activities/agricole"
          />
        </div>
      </div>
      {/* <PopView show={showGallery} handleClose={() => setShowGallery(false)} /> */}
    </div>
  );
};

export default ActivitiesPage;
