import { createBrowserRouter } from "react-router-dom";

import Root from "./root";
import ErrorPage from "./pages/errorpage";
import LandingPage from "./pages/landingpage";
import AboutPage from "./pages/aboutpage";
import ActivitiesPage from "./pages/activitiespage";
import RessourcesPage from "./pages/ressourcespage";
import DonatePage from "./pages/donatepage";
import AdhesionPage from "./pages/adhesionpage";
import ContactPage from "./pages/contactpage";
import Olympiade from "./portefolio/olympiade";
import Biblio from "./portefolio/biblio";
import AgriEcole from "./portefolio/agricole";
import Educine from "./portefolio/educ-cine";
import Elle from "./portefolio/p-elle";
import Restaurant from "./portefolio/restau";
import MentionsLegales from "./pages/mentionspage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <LandingPage />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/activities",
        element: <ActivitiesPage />,
      },
      {
        path: "/ressources",
        element: <RessourcesPage />,
      },
      {
        path: "/adhesion",
        element: <AdhesionPage />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
      {
        path: "/donate",
        element: <DonatePage />,
      },
      {
        path: "/cgus",
        element: <MentionsLegales />,
      },
      {
        path: "/activities/biblio",
        element: <Biblio />,
      },
      {
        path: "/activities/olympiade",
        element: <Olympiade />,
      },
      {
        path: "/activities/restau",
        element: <Restaurant />,
      },
      {
        path: "/activities/educ-cine",
        element: <Educine />,
      },
      {
        path: "/activities/agricole",
        element: <AgriEcole />,
      },
      {
        path: "/activities/p-elle",
        element: <Elle />,
      },
    ],
  },
]);

export default router;
