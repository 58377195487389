import Breadcrumb from "../breadcrumb/breadcrumb";

const PageHeader = ({ breadcrumbs, title }) => (
  <div
    className="container-fluid page-header mb-5 wow fadeIn"
    data-wow-delay="0.1s"
  >
    <div className="container text-center">
      <h1 className="display-4 text-white animated slideInDown mb-4 fw-bold">
        {title}
      </h1>
      <Breadcrumb links={breadcrumbs} />
    </div>
  </div>
);

export default PageHeader;
