import { useTranslation } from "react-i18next";

const Historique = () => {
  const { t } = useTranslation();
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div
          className="text-center hsitoire mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
            {t("histoire.notre")}
          </div>
          <h1 className="display-6 mb-5 fw-bold text-dark">
            {t("histoire.title1")}
          </h1>
        </div>
        <div className="bg-light border-bottom border-5 border-primary rounded p-4 mb-4">
          <p className="text-dark mb-2">
            Selon l’UNICEF, en 2019, 17% d’enfants en âge de fréquenter l’école
            primaire sont non scolarisés en Afrique.
          </p>
          <p className="text-dark mb-2">
            L’ONG APEA a été créée par la volonté des jeunes ingénieurs de la
            diaspora africaine, souhaitant contribuer à l’accès à l’éducation de
            tous les enfants en Afrique.
          </p>
          <p className="text-dark mb-2">
            APEA est une association à but non lucratif Loi 1901 libre et
            indépendante fondée en Juin 2022.
          </p>
          <p className="text-dark mb-2">
            Elle est publiée dans le Journal Officiel des associations N°28 paru
            le Mardi 12 Juillet 2022
          </p>
          <p className="text-dark mb-2">
            Elle est enregistrée auprès des greffes des associations sous le
            numéro W783012285 et dans le Système d’Identification du Répertoire
            des Établissements (SIRET) sous le numéro 71935037100010.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Historique;
