import ActivitiesesItem from "./activitiesItem";
import causeCover1 from "../../../img/olympiade/cover.jpeg";
import causeCover2 from "../../../img/biblio/cover.jpeg";

const Causes = () => {
  return (
    <div className="container-xxl causes bg-light my-5 py-5">
      <div className="container py-5">
        <div
          className="text-center mx-auto mb-5 wow fadeInUp causes-title-container"
          data-wow-delay="0.1s"
        >
          <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
            Activités réalisées
          </div>
          <h1 className="display-6 mb-5 fw-700 text-dark">
            Nos modestes réalisations pour l'Excellence
          </h1>
        </div>
        <div className="row g-4 justify-content-center">
          <ActivitiesesItem
            category="Education"
            title="Olympiades de l'Excellence"
            description="Les olympiades de mathématiques, de dictée et de culture générale,"
            cover={causeCover1}
            readmore="/activities/olympiade"
          />
          <ActivitiesesItem
            category="Don de livre"
            title="Une bibliothèque pour mon village"
            description="Équiper chaque village du département de Bocanda d’une micro bibliothèque de 100 livres."
            cover={causeCover2}
            readmore="/activities/biblio"
          />
        </div>
      </div>
    </div>
  );
};

export default Causes;
